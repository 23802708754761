import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import { Box, Flex, Text, Heading } from "@chakra-ui/react";

import "@fontsource/montserrat/100.css";
import "@fontsource/montserrat/200.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/montserrat/900.css";
import "@fontsource/work-sans/100.css";
import "@fontsource/work-sans/200.css";
import "@fontsource/work-sans/300.css";
import "@fontsource/work-sans/400.css";
import "@fontsource/work-sans/500.css";
import "@fontsource/work-sans/600.css";
import "@fontsource/work-sans/700.css";
import "@fontsource/work-sans/800.css";
import "@fontsource/work-sans/900.css";
import "@fontsource/cardo/400.css";
import "@fontsource/cardo/700.css";
import "@fontsource/square-peg";
const About = ({ location }) => {
  const imgWidth = 200;
  return (
    <Layout bgColor="white" path={location.pathname}>
      <Flex
        direction="column"
        align="center"
        py="30px"
        maxW={{ base: "300px", sm: "450px", md: "600px" }}
        fontFamily="Work Sans"
        margin="auto"
      >
        <Heading
          w="100%"
          color="green.500"
          as="h1"
          mb="2"
          fontFamily="Montserrat"
        >
          Welcome to MCI and Beyond
        </Heading>
        <Text variant="aboutText">
          In early 2021 my wife was diagnosed with MCI and I became her primary
          caretaker.
          <Text display="block">
            {" "}
            I began looking for reliable sources of information to help educate
            and guide me. I found a lot of reference points and many were of
            high quality and educational. But I also found that they were
            scattered all over the place with no central clearing house that
            vets and shares the best of the best.
          </Text>
        </Text>

        <Flex direction={{ base: "column", md: "row" }} align="center">
          <Flex
            w={{ base: "100px", sm: "120px", md: "140px" }}
            pr={{ base: "0", md: "6" }}
          >
            <StaticImage
              width={imgWidth}
              src={"../images/ux.png"}
              alt="desktop computer graphic"
            />
          </Flex>
          <Text variant="aboutText" as="aboutText" w="100%">
            I wanted to contribute by building a website that curates and shares
            the latest information about MCI and Dementia related issues. That’s
            what this website is about: to provide and share information to Care
            Partners of loved ones diagnosed with Dementia and especially those
            with MCI (mild cognitive impairment). My intent is to curate
            articles, books, videos and personal perspectives, a “Go To” for
            credible and timely information about Dementia and MCI.
          </Text>
        </Flex>

        <Flex direction={{ base: "column-reverse", md: "row" }} align="center">
          <Text variant="aboutText" as="aboutText" w="100%">
            My wife and I have joined the Cognitive Empowerment Program - CEP,
            (https://empowerment.emory.edu), a unique research partnership
            between Emory University with the Georgia Institute of Technology
            that provides people with Mild Cognitive Impairment and their Care
            Partners and families an opportunity to participate in comprehensive
            lifestyle programs that promote joy, purpose, health, and wellness.
            This holistic program has been a blessing, providing us with
            information and best practices from experts and an opportunity to
            interact with others on this journey.
          </Text>
          <Flex
            w={{ base: "100px", sm: "120px", md: "140px" }}
            pl={{ base: "0", md: "6" }}
          >
            <StaticImage
              width={imgWidth}
              src={"../images/videoconference.png"}
              alt="video conference graphic"
            />
          </Flex>
        </Flex>

        <Flex direction={{ base: "column", md: "row" }} align="center">
          <Flex
            w={{ base: "100px", sm: "120px", md: "140px" }}
            pr={{ base: "0", md: "6" }}
          >
            <StaticImage
              width={imgWidth}
              src={"../images/email.png"}
              alt="email graphic"
            />
          </Flex>
          <Text
            variant="aboutText"
            as="aboutText"
            py="5"
            display="inline"
            w="100%"
          >
            If you would like to offer any suggestions on how to improve this
            site or would like to contribute with content, please write to me
            at:{" "}
            <Text
              display="inline"
              color="green.600"
              fontWeight="500"
              fontSize="16px"
              py="2"
              as="a"
              href="mailto:mciandbeyond@gmail.com"
            >
              {" "}
              mciandbeyond@gmail.com
            </Text>
          </Text>
        </Flex>

        <Box w="100%" mt="2" align="center">
          <Text py="2">Thank you,</Text>
          <Text fontFamily="Square Peg" fontSize="50px">
            Ronald Durchfort
          </Text>
        </Box>
      </Flex>
    </Layout>
  );
};

export default About;
